.u-width {
  $uWidth: &;

  &--5-p {
    width: map-get($map: $values, $key: '5%');
  }
  &--95-p {
    width: map-get($map: $values, $key: '95%');
  }
}
