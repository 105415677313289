body {
  background-color: #ffd88c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.background-color {
  background-color: yellowgreen;
}





.blur {
  background: rgba(219, 21, 21, 0.2);
  backdrop-filter: blur(8px);
}

.btn-icon-edit {
  padding: 10px;
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  transform: translateY(0);
}

.padding-manage {
  padding-right: 110px;
}

.btn-icon {
  margin-top: 32px;
  padding: 10px;
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  transform: translateY(0);
}

.btn-icon:hover {
  transform: translateY(-3px);
}

.btn-icon:hover svg {
  fill: #d9534f;
}


.btn-txt-icon span {
  vertical-align: middle;
}

.add-more-icon {
  background-color: none !important;
  color: #3ba371;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer;
  transform: translateY(0) !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.add-more-icon svg {
  margin: 0 !important;
  width: 24px !important;
  height: 24px !important;
}

.add-more-icon span {
  display: inline-block;
  vertical-align: middle;
}

.add-more-icon:hover,
.add-more-icon:active,
.add-more-icon:target {
  color: #525f7f !important;
  box-shadow: none !important;
  background-color: transparent !important;
  background: none !important;
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}


/* select.select-custom option {
  background-color: #fff !important;
  background: #fff !important;
  color: #000 !important;
  padding: 6px 12px !important;
  font-size: 15px; font-weight: 400 !important;
} */
/* select.select-custom option:nth-child(2) {
    background-color: #fb6340 !important;
    background: #fb6340 !important;
    color: #fff !important;
    padding: 6px 12px !important;
    font-size: 15px; font-weight: 600 !important;
    text-transform: uppercase;
  } */

/*onfocus="this.size=2;" onblur="this.size=0;" onchange="this.size=1; this.blur()"*/


.select-box {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.select-box--parent {
  display: block;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  background: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  padding: 0.625rem 1.75rem 0.625rem 0.75rem;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
}

.select-box .select-box--parent:hover {
  cursor: pointer;
}

.select-box--parent:after {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -2px;
  top: 6px;
  content: "\2304";
  font-size: 24px;
  font-weight: 400;
  color: #666;
  text-align: center;
  line-height: 16px;
}


.select-box-dorpdown {
  border-radius: 0;
  border: none;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99;
  transition: all 0.5s ease;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 0.375rem;
  border: none;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

/*ul.select-box li:hover > ul.select-box-dorpdown,
  ul.select-box li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }*/
.select-box-dorpdown li {
  clear: both;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 0.325rem 0.75rem;
}

.select-box-dorpdown li:hover {
  color: #111;
}

.select-box-dorpdown li:last-of-type {
  background: #fff;
  color: #fb6340;
}

.select-box-dorpdown li:hover {
  background: #fff;
  color: #111;
}

.option-blue {
  color: blue
}

.w-60 {
  width: 60%
}

.option-select-property {
  background-color: #278e69;
  color: #fff;
}

.li-background-property {
  background-color: #278e69 !important;
  color: #fff !important;
}




.c-table-product {
  border: none;
  table-layout: fixed;
  width: 100%;
}

.c-table-product th,
.c-table-product td {
  border: none;
  width: 25%;
  word-break: break-word;
  white-space: normal;
}

@media(min-width:576px) {
  .modal-dialog {
    max-width: 60% !important;
  }
}

b,
strong {
  font-weight: bold !important;
}

.card-img-top {
  border: 3px solid black;
  box-sizing: border-box;
}



.btn-box {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 6px;
  padding: 0 10px;
  margin: 0 0 0 15px;
  position: relative;
  text-align: center;
  font-size: 26px;
  line-height: 42px;
  box-shadow: 0 3px 4px rgb(50 50 93 / 0), 0 1px 3px rgb(0 0 0 / 0);
}

.btn-box:hover,
.btn-box:focus {
  background-color: #3ba371;
  color: #fff;
  box-shadow: 0 4px 4px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-minus {
  color: red;
  font-size: 32px;
}

.btn-minus:hover {
  background-color: red;
}


.btn-addmore {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 24px;
}

.btn-addmore .icon {
  font-size: 20px;
}

.btn-addmore:hover,
.btn-addmore:focus {
  color: #000;
}

/* .btn-addmore {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 24px;
}

.btn-addmore .icon {
  font-size: 20px;
}

.btn-addmore:hover,
.btn-addmore:focus {
  color: #000;
} */


.table-views {
  border: none !important;
}

.table-views tr {
  border: none;
  margin-bottom: 25px;
}

.table-views th,
.table-views td {
  padding: 10px;
  border: none !important;
}

.table-views th {
  border: #eee solid 1px;
}

.table-views td {
  border: #eee solid 1px;
}



.uploadfiles {
  margin-bottom: 20px;
  display: block;
}

.uploadfiles h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.uploadfiles--box {
  width: auto;
  display: inline-block;
  padding: 10px 60px 10px 10px;
  overflow: hidden;
  background-color: #f1f0f0;
  border-radius: 8px;
  position: relative;
  font-size: 14px;
  color: #000;
}

.uploadfiles--box a {
  display: block;
  text-decoration: underline;
  font-weight: 600;
  color: #000;
}

.uploadfiles--icon {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}


.btn-icon-close {
  width: 15px;
  height: 15px;
  background-color: #000;
  text-align: center;
  color: #fff !important;
  font-size: 8px;
  line-height: 14px;
  text-decoration: none !important;
  border: none;
  border-radius: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
}


.scroll-table-area {
  overflow-x: auto;
}

@media(max-width:991px) {
  .scroll-table-area table {
    width: 150%;
  }
}

@media(max-width:575px) {
  .scroll-table-area table {
    width: 240%;
  }
}

.fontsize-navbar {
  font-size: 14px;
}
.bg-green-color{
  color:#3ba371
}