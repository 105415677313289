.s-table {
  $sTable: &;

  &--content-list {
    tr {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
