//
// Close
//

.close {
    @if $enable-transitions {
        transition: $transition-base;
    }
    
    &>span:not(.sr-only) {
        background-color: $close-bg;
        color: $close-color;
        line-height: 17px;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 50%;
        font-size: 1.25rem;
        display: block;
        @if $enable-transitions {
            transition: $transition-base;
        }
    }

    &:hover,
    &:focus {
        background-color: $close-hover-bg;
        color: $close-hover-color;
        outline: none;

        span:not(.sr-only) {
            background-color: $close-hover-bg;
        }
    }
}
.checkbox-design {
    margin: 0 0 5px;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid gray;
}

.styled-checkbox:hover+label:before {
    background: #f35429;
}

.styled-checkbox:checked+label:before {
    background: #f35429;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}
