.kn-search__search___3Zpb2 {
  background-color: #eaeaea !important;
}

.inputBoxUpload-wrapper {
  min-height: 50px;
}

.secondary-body-min-height {
  min-height: 20vh;
}

.selectedFile {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 15px 0px;
  width: 65%;
}

.file-details {
  width: 85%;
  word-break: break-all;
  font-size: 12px;
}

.file-details p {
  color: "#e30613";
  margin-bottom: 0px;
}

.file-details .date {
  font-size: 10px;
}

.delete-file {
  color: "#e30613";
  font-size: 16px;
}
.delete-file:hover {
  cursor: pointer;
}

.draggable {
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50%;
}

.drag-text {
  color: #cac5c5;
  font-size: 12px;
  margin: 10px 0px !important;
}

.draggable:focus {
  outline: none;
}
.draggable:hover {
  cursor: pointer;
}

.fa-cloud-upload-alt {
  font-size: 16px;
  color: #d5d5d4;
  padding-right: 10px;
}

.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.switch-wrapper.large {
  height: 40px;
  width: 80px;
}
.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 3px;
  width: 14px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  float: left;
  margin-left: 10px;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}

.no-effects,
.no-effects:active,
.no-effects:focus {
  box-shadow: none !important;
  background: transparent !important;
  padding: 0px;
}

.no-transform {
  transform: none !important;
}

.border-none {
  border: none !important;
}

.block-wrapper {
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  padding: 20px;
  margin-bottom: 20px;
}

.table-responsive {
  min-height: 150px;
}

.blue-text {
  color: blue;
}
iframe {
  display: none !important;
}

.css-1s2u09g-control,
.css-1s2u09g-control:active,
.css-1s2u09g-control:hover,
.css-1s2u09g-control:focus,
.css-1pahdxg-control:hover,
.css-1pahdxg-control {
  border-radius: 0.375rem !important;
  border: none !important;
  outline: none !important;
  font-size: 0.875rem !important;
  min-height: calc(1.5em + 1.25rem + 2px) !important;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%) !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-tj5bde-Svg {
  color: #8898aa !important;
  height: 17px !important;
}
.css-26l3qy-menu * {
  background-color: transparent;
}
.css-26l3qy-menu .active,
.css-26l3qy-menu .selected {
  background-color: red;
}

.removePic {
  z-index: 1;
}
.file-input input {
  opacity: 0;
  position: absolute;
  left: 0px;
  height: 64px;
  max-width: 100%;
  cursor: pointer;
}
.profile-img {
  height: 85px;
  width: 85px;
  border-radius: 50%;
}
.ctaRow {
  align-items: center;
  color: #122132;
  display: flex;
  font-family: SF Pro Display Bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
  justify-content: space-between;
  height: 100%;
}
.ctaRow img {
  margin-right: 16px;
}
.ctaRow .removePic {
  position: absolute;
  left:75px;
  top: -1px;
  height: 25px;
  width: 25px;
}
.move-left {
  left: 202px !important;
}
