@use '../abstracts/maps' as *;

.u-flex {
  $uFlex: &;

  &--16 {
    flex: map-get($map: $values, $key: 0) map-get($map: $values, $key: 0)
      map-get($map: $values, $key: 16);
  }
}
