@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.u-background {
  $uBackground: &;

  &--color-blue-1-1 {
    background: $color-blue-1-1;
  }
}
