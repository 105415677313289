// Use any other files required here
@use 'sass:map';

$values: (
  0: 0,
  12: toRem(12),
  16: toRem(16),
  53: toRem(53),
  'auto': auto,
  '5%': 5%,
  '95%': 95%,
  '100%': 100%,
);
